import axios from "axios";
import BASEURL from "./Constants";
import {useState} from "react";


const GlobalFunctions = {
    slugFormatter(slug) {
        slug = slug.replaceAll('&', ' and ')
        slug = slug.replaceAll(' & ', ' and ')
        slug = slug.replaceAll(' ', '-')
        slug = slug.replaceAll('--', '-')
        slug = slug.replaceAll(' / ', '-')
        slug = slug.replaceAll('-/-', '-')
        slug = slug.replaceAll('/', '-')
        slug = slug.replaceAll(',', '-')
        slug = slug.replaceAll(' , ', '-')
        slug = slug.replaceAll(', ', '-')
        slug = slug.replaceAll(';', '-')
        slug = slug.replace(/[^a-zA-Z0-9]+/g, '-')
        slug = slug.toLowerCase()
        return slug
    },
    handleInvalidImageUrl(e) {
        e.target.src = require('./Assets/Images/loader/img_bw.png')
    },
    handleAuthorization(){
        let url = window.location.pathname
        let auth
        if (url != '/login'){
            // axios.post(BASEURL+'/api/check-user-auth').then(res=>{
            //     if (res.data.role_id == localStorage.role_id && res.data.user_id == localStorage.user_id){
            //         let auth =res.data.msg
            //     }else {
            //         localStorage.removeItem('user_token')
            //         localStorage.removeItem('name')
            //         localStorage.removeItem('email')
            //         localStorage.removeItem('photo')
            //         localStorage.removeItem('user_id')
            //         localStorage.removeItem('role_id')
            //         localStorage.removeItem('photo_thumb')
            //         window.location.href = '/login'
            //     }
            // })
        }
    }
}
export default GlobalFunctions