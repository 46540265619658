import React, {useEffect, useState} from 'react';
import {faBell, faCommentDots, faEye, faPlus, faSearch, faFileUpload, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import user from '../../Assets/Images/2.jpg'
import $ from 'jquery'
import Swal from "sweetalert2";
import axios from "axios";
import BASEURL from "../../Constants";
import {Link} from "react-router-dom";

const TopBar = () => {

    const handleUserLoginArea = () => {
      $('#admin_logout_area').toggle()
    }


    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You Will Be Logged Out",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(BASEURL+'/api/user-logout').then(res=>{
                    localStorage.removeItem('name')
                    localStorage.removeItem('user_token')
                    localStorage.removeItem('email')
                    localStorage.removeItem('photo_thumb')
                    localStorage.removeItem('user_id')
                    localStorage.removeItem('role_id')
                    window.location.reload();
                })
            }
        })
    }
const role_id = localStorage.getItem("role_id");
const user_token = localStorage.user_token;


const [workDuration, setWorkDuration] = useState([])
const getUserPunchDataSummary = () =>{
    axios.post(BASEURL+'/api/user-punch-time-duration').then(res=>{
       setWorkDuration(res.data)
    })
}

useEffect(()=>{
    if(role_id != 1 && user_token != null){
        setTimeout(function() {
            getUserPunchDataSummary()
       }, 0);
}
}, [])


useEffect(()=>{
    if(role_id != 1 && user_token != null){
    setInterval(()=>{
        getUserPunchDataSummary()
   }, 60000)
}
}, [])

    return (
        <div className="top-bar-section">
            <div className="top-search-section">
                <div className="top-search-and-icon d-none">
                    <form>
                        <input type="text" className={'input-search-box-top-bar'} placeholder={'Search'}/>
                        <FontAwesomeIcon icon={faSearch} className={'top-bar-search-icon'}/>
                    </form>
                </div>
                <div className={role_id == 1 ? 'col-md-4  mt-3 mt-md-0 d-none' : workDuration.status != null ? 'col-md-4  mt-3 mt-md-0 ' : 'col-md-4  mt-3 mt-md-0 d-none'}>
                    <h5 className={workDuration.status == 'Working' ? 'hours-design' : workDuration.status == 'Worked' ? 'hours-design' : workDuration.status == 'Not Working' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Not Found' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Issue' ? 'hours-design hours-design-danger' : 'hours-design hours-design-danger'}>{workDuration.status}</h5>
                    <h5 className={workDuration.duration == 'Punch Missing' ? 'hours-design hours-design-danger' : workDuration.duration == '--' ? 'hours-design hours-design-danger' : 'hours-design'}>{workDuration.duration}</h5>
                </div>
                <div className="user-notification-and-msg">
                    <div className="notification-icon-and-msg-icon">
                        {/*<FontAwesomeIcon icon={faCommentDots} className={'massage-icon'}/>*/}
                        {/*<FontAwesomeIcon icon={faBell} className={'massage-icon'}/>*/}
                        <Link to={'/'}><FontAwesomeIcon icon={faEye} className={'massage-icon'}/></Link>
                        <Link to={'/punch/calculate'}><FontAwesomeIcon icon={faClock} className={'massage-icon'}/></Link>
                        {role_id == 1 ? <Link to={'/punch/create'}><FontAwesomeIcon icon={faPlus} className={'massage-icon'}/></Link> : null}
                        {role_id == 1 ? <a href={'https://team-data.iithost.com/upload-punch'} target='_blank'><FontAwesomeIcon icon={faFileUpload} className={'massage-icon'}/></a> : null}


                    </div>
                    <div className="user-and-name position-relative" onClick={handleUserLoginArea}>
                        <p className={'admin-name'}>{localStorage.name != undefined?localStorage.name:null}</p>
                        <img src={localStorage.photo_thumb != undefined && localStorage.photo_thumb != null?localStorage.photo_thumb :user} className={'user-img'} alt=""/>


                        <div className="drop-menu-user position-absolute text-center" id={'admin_logout_area'}>
                            <button onClick={logout} className={'logout-button'}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
