import React, {useEffect, useState} from 'react';
import loader from "../../../Assets/Images/loader/ripple.svg";
import Pagination from "react-js-pagination";
import axios from "axios";
import BASEURL from "../../../Constants";
import {Helmet} from "react-helmet";
import Constants from "../../../Constants";

const ViewPunch = () => {


    const [searchString, setSearchString] = useState('')
    const [user_id, setUser_id] = useState('')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPageItem, setPerPageItem] = useState(20)
    const [punches, setPunches] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [serial, setSerial] = useState(1)
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [sendRequestWithDate, setSendRequestWithDate] = useState(false)
    const [autoRefresh, setAutoRefresh] = useState(false)
    const [timeOutTime, setTimeOutTime ] = useState(null)

    const handleAutoRefresh = (e) => {
        if (e.target.value == true){
            setAutoRefresh(false)
        }else{
            setAutoRefresh(true)
        }
    }

    useEffect(()=>{
        let Auto
        if (autoRefresh == true){
             Auto = setInterval(()=>{
                 getUpdatedPunch()
            }, 15000)
        }else{
            clearInterval(Auto)
        }

    }, [autoRefresh])

    const handleDateInput = (e, type) => {
      if (type == 'start'){
          setStartDate(e.target.value)
          if (endDate != null){
              setSendRequestWithDate(true)
          }
      }else{
          setEndDate(e.target.value)
          if (startDate != null){
              setSendRequestWithDate(true)
          }
      }
    }

    useEffect(() => {
        getPunch()
        setSendRequestWithDate(false)
    }, [perPageItem, user_id, sortDirection, sendRequestWithDate])
    const handleOrderBy = (e) => {
        setUser_id(e.target.value)
    }
    const handleSortDirection = (e) => {
        setSortDirection(e.target.value)
    }
    const setPerPageData = (e) => {
        setPerPageItem(e.target.value)
    }
    const handleModal = () => {

    }

    const getUpdatedPunch = (pageNumber = 1) => {
        axios.get(BASEURL + '/api/get-all-punch-data?page=' + pageNumber + '&per_page=' + perPageItem + '&user_id=' + user_id + '&sort_direction=' + sortDirection + '&startDate=' + startDate + '&endDate='+endDate).then(res => {
            setPunches(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSerial(res.data.meta.from)
            setIsLoading(false)
        })
    }

    const getPunch = (pageNumber = 1) => {
        setIsLoading(true)
        setPunches([])
        axios.get(BASEURL + '/api/get-all-punch-data?page=' + pageNumber + '&per_page=' + perPageItem + '&user_id=' + user_id + '&sort_direction=' + sortDirection + '&startDate=' + startDate + '&endDate='+endDate).then(res => {
            setPunches(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSerial(res.data.meta.from)
            setIsLoading(false)
        })
    }

    const getUser = () => {
      axios.get(BASEURL+'/api/user-list').then(res=>{
          setUsers(res.data)
      })
    }

    useEffect(() => {
        getUser()
        setUser_id(localStorage.user_id!=undefined && localStorage.user_id != 1?localStorage.user_id:'')
        // setWorkDuration(prevState => ({...prevState, duration : 0}))
    }, []);

    return (
        <>
            <Helmet>
                <title>Punches | iIT Team</title>
            </Helmet>
            <div className="row margin-top-in-custom">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="all-order-title position-relative">
                            <div className="in-out-status">
                                <h5 dangerouslySetInnerHTML={{__html: startDate == null && endDate == null ?  (totalItemsCount%2==1?'<span class="text-white rounded bg-success px-1">In</span>' : '<span class="text-white rounded bg-danger px-1">Out</span>'):null}}/>
                            </div>
                            <h5 className={'top-selling-product-name'}>Punch List</h5>
                            <label>
                                Auto Refresh
                                <div className="form-switch ms-2">
                                    <input onClick={(e) => handleAutoRefresh(e)}
                                           className="form-check-input custom-switch" defaultValue={autoRefresh}
                                           type="checkbox" defaultChecked={autoRefresh}
                                    />
                                </div>
                            </label>
                        </div>

                        <div className="row mt-5 mb-4">
                            <div className="col-md-2">
                                <div className="search-field-and-icon position-relative">
                                    <input onChange={(e) => setSearchString(e.target.value)} type={'search'}
                                           className={'manage-order-search'}
                                           placeholder={'Search'}/>
                                    {/*<FontAwesomeIcon icon={faSearch} className={'search-icon-in-all'}/>*/}
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-3 g-0">
                                        <label>
                                            <select defaultValue={user_id} onChange={handleOrderBy}
                                                    className="form-select custom-form-select-in-order-punch">
                                                {users.map((user, index)=>(
                                                    <option selected={localStorage.user_id  == user.user_id ? true: false} key={index} value={user.user_id}>{user.user_id} - {user.name}</option>
                                                ))}

                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-md-3 mt-1">
                                        <div className="row">
                                            <div className=" clo-xs-6 col-md-6 d-flex">
                                                <label className={'d-flex'}>
                                                    <select onChange={handleSortDirection}
                                                            className="form-select custom-form-select-in-order ms-2">
                                                        <option value={'asc'}>ASC</option>
                                                        <option  selected={true} value={'desc'}>DESC</option>
                                                    </select>
                                                </label>
                                                <label className={'d-flex'}>
                                                    <select onChange={setPerPageData}
                                                            className="form-select custom-form-select-in-order ms-2">
                                                        <option value={20}>20 Entries</option>
                                                        <option value={50}>50 Entries</option>
                                                        <option value={100}>100 Entries</option>
                                                        <option value={200}>200 Entries</option>
                                                        <option value={500}>500 Entries</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clo-xs-6 col-md-6 text-end">
                                        <div className="date-select-area">
                                            <label>
                                                From
                                                <input onChange={(e)=>handleDateInput(e,'start')} type={'date'} className={'manage-order-search'}/>
                                            </label>
                                            <label>
                                                To
                                                <input onChange={(e)=>handleDateInput(e, 'end')} type={'date'} className={'manage-order-search'}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative custom-responsive">
                            <table className={'delivery-information-table custom-box-shadow-inner table-responsive'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Staff ID</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Type</th>
                                    <th>Working Hours</th>
                                    <th>In/Out</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {punches.map((punch, index) => (
                                    <tr key={index}>
                                        <td>{serial + index} </td>
                                        <td>{punch.user_id}</td>
                                        <td>{punch.user_name}</td>
                                        <td>{punch.punch_date} <span className={'ms-2'}>{punch.punch_time_sec}</span></td>
                                        <td>{punch.punch_time}</td>
                                        <td>{punch.punch_type}</td>
                                        <td>Working Hour</td>
                                        <td>Thinking... </td>
                                        <td>Action</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {Object.keys(punches).length < 1 && isLoading == false ?
                                <div className={'data-not-found'}>No Data Found</div> : null}
                            {isLoading ?
                                <div className="loader">
                                    <img className={'loader-image'} src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    Showing {serial} to {serial + perPageItem - 1 <= totalItemsCount ? serial + perPageItem - 1 : totalItemsCount} of {totalItemsCount} entries
                                </div>
                                <div className="col-md-6">
                                    {totalItemsCount > perPageItem ?
                                        <nav className={'mt-2'}>
                                            <Pagination
                                                innerClass={'pagination justify-content-end'}
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                // pageRangeDisplayed={5}
                                                onChange={getPunch}
                                                nextPageText="Next"
                                                prevPageText="Previous"
                                                firstPageText="First"
                                                lastPageText="Last"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </nav> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default ViewPunch;
