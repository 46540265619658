import React, {useEffect, useState} from "react";
import './Assets/style.css'
import {BrowserRouter, Route} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import Home from "./Components/Pages/Home";
import TopBar from "./Components/Include/TopBar";
import SideNavBar from "./Components/Include/SideNavBar";
import LoginPage from "./Components/Include/LoginPage";
import ImageUploader from "./Components/Include/ImageUploader";
import Brand from "./Components/Pages/Brands/Brand";
import Auth from "./Auth";
import 'react-toastify/dist/ReactToastify.css';
import ViewPunch from "./Components/Pages/Punch/ViewPunch";
import PunchList from "./Components/Pages/Punch/PunchList";
import Calculate from "./Components/Pages/Punch/Calculate";
import CreatePunch from "./Components/Pages/Punch/CreatePunch";
import GlobalFunctions from "./GlobalFunctions";




function App() {

    const [url, setUrl]= useState('')
    useEffect(()=>{
        setUrl(window.location.pathname)
      if (url != '/login'){
          setInterval(()=>{
              GlobalFunctions.handleAuthorization()
          }, 30000)
      }

    }, [])



    return (
    <div className="App">
        {url == '/login'?
            <BrowserRouter>
                <Route exact path="/login" component={LoginPage}/>
            </BrowserRouter>:
            <BrowserRouter>
                <div className="container-fluid g-0 overflow-hidden">
                    <div className="row">
                        <div className="col-xs-6 col-md-2">
                            <SideNavBar/>
                        </div>
                        <div className="col-xs-6 col-md-10">
                            <TopBar/>
                            <div className="container mr-top-header-content">
                                <Auth exact path="/home" component={Home}/>
                                <Auth path="/punch/calculate" component={Calculate}/>
                                <Auth exact path="/" component={PunchList}/>
                                <Auth path="/punch/view" component={ViewPunch}/>
                                <Auth path="/punch/create" component={CreatePunch}/>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        }




    </div>
  );
}

export default App;
