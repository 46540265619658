import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery'
import axios from "axios";
import BASEURL from "../../Constants";
import {useHistory} from "react-router-dom";

const LoginPage = () => {

    const history = useHistory();
    const [input, setInput] = useState({})
    const [errMsg, setErrMsg] =useState({})
    const handleInput = (e) => {
        setInput(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const login = (e) =>{
        e.preventDefault()
        axios.post(BASEURL+'/api/user-login', input).then(res=>{
            localStorage.name = res.data.data.name
            localStorage.email = res.data.data.email
            localStorage.user_token = res.data.data.token
            localStorage.photo_thumb = res.data.data.photo_thumb
            localStorage.user_id = res.data.data.user_id
            localStorage.role_id = res.data.data.role_id
            history.push('/')
            window.location.reload()
        }).catch(errors=>{
            if(errors.response.status == 422){
                setErrMsg(errors.response.data.errors)

              //  console.log(errors.response.data.errors)
            }
        })
    }



    const showPassword = () => {
        let element = $('#password')
        let show_status = element.attr('type')
        if (show_status == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }

    useEffect(()=>{
        if (localStorage.user_token != undefined){
            history.push('/')
            window.location.reload()
        }
    }, [])


    return (
        <div className="container-fluid g-0 overflow-hidden">
            <div className="row">
                <div className="col-md-12">
                    <div className="login-form-section">
                        <div className="login-form-inner">
                            <form className={'login-inner-box'}>
                                <div className="mb-3">
                                    <input type="email" onChange={handleInput} name={'email'}
                                           className="form-control login-form-control"
                                           placeholder={'Email'}/>
                                    {Object.entries(errMsg).map(([key, val]) => (
                                        <p  key={key}>
                                            <small
                                                className="text-danger validation-error-message">
                                                {key == 'error_msg' ? val : ''}
                                            </small>
                                        </p>
                                    ))}

                                </div>
                                <div className="mb-3">
                                    <div className="password-input position-relative">
                                        <input type="password" onChange={handleInput} name={'password'}
                                               className="form-control login-form-control"
                                               placeholder={'Password'} id={'password'}/>
                                        <span className={'show-password'} onClick={showPassword}><i
                                            className={'fas fa-eye text-success'}/> </span>

                                        {Object.entries(errMsg).map(([key, val]) => (
                                            <p  key={key}>
                                                <small
                                                    className="text-danger validation-error-message">
                                                    {key == 'password' ? val : ''}
                                                </small>
                                            </p>
                                        ))}

                                    </div>
                                </div>
                                <div className="remember-and-forgot-pass">
                                    <div className="remember">
                                        <input className="form-check-input" type="checkbox"/><span>Remember</span>
                                    </div>
                                    <Link to={'/'} className={'forgot-title'}>Forgot Your Password?</Link>
                                </div>
                                <div className="submit-btn">
                                    <button type="submit" onClick={login} className="login-btn">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
