import React, {useEffect, useState} from 'react';
import axios from "axios";
import BASEURL from "../../../Constants";

const Calculate = () => {

    const [users, setUsers] = useState([])
    const [user_id, setUser_id] = useState('')
    
    const getUser = () => {
        axios.get(BASEURL+'/api/user-list').then(res=>{
            setUsers(res.data)
        })
      }
  
      useEffect(() => {
          getUser()
          getUserPunchDataSummary()
          setUser_id(localStorage.user_id!=undefined && localStorage.user_id != 1?localStorage.user_id:'')
          // setWorkDuration(prevState => ({...prevState, duration : 0}))
      }, []); 


    const [topInput, setTopInput] = useState([])

    const handleTopInput = (e) => {
        setTopInput(prevState => ({...prevState, [e.target.name] : e.target.value}))
        // console.log(topInput)
    }
    
    const [workDuration, setWorkDuration] = useState([])
    const getUserPunchDataSummary = () =>{
        axios.post(BASEURL+'/api/user-punch-time-duration', topInput).then(res=>{
           setWorkDuration(res.data)
        })
    }
    useEffect(()=>{
        getUserPunchDataSummary()
    }, [topInput])

    return (
        <div className="row margin-top-in-custom">
            <div className="col-md-12">
                
            <div className="row ">
                        <div className="col-md-12 px-4">
                            <div className="row new-card-section">
                                <div className="col-md-4 ">
                                    <select
                                        className="form-select custom-select-section"
                                        name={'user_id'}
                                        onChange={handleTopInput}
                                        value={topInput.user_id}
                                    >
                                        {users.map((user, index)=>(
                                            <option selected={localStorage.user_id  == user.user_id ? true: false} key={index} value={user.user_id}>{user.user_id} - {user.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4 mt-3 mt-md-0">
                                    <input
                                        type="date"
                                        className={'custom-select-section'}
                                        name={'date'}
                                        onChange={handleTopInput}
                                        value={topInput.date}
                                    />
                                </div>
                                <div className="col-md-4  mt-3 mt-md-0">
                                    <h5 className={workDuration.status == 'Working' ? 'hours-design' : workDuration.status == 'Worked' ? 'hours-design' : workDuration.status == 'Not Working' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Not Found' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Issue' ? 'hours-design hours-design-danger' : 'hours-design hours-design-danger'}>{workDuration.date}</h5>
                                    <h5 className={workDuration.status == 'Working' ? 'hours-design' : workDuration.status == 'Worked' ? 'hours-design' : workDuration.status == 'Not Working' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Not Found' ? 'hours-design hours-design-danger' : workDuration.status == 'Punch Issue' ? 'hours-design hours-design-danger' : 'hours-design hours-design-danger'}>{workDuration.status}</h5>
                                    <h5 className={workDuration.duration == 'Punch Missing' ? 'hours-design hours-design-danger' : workDuration.duration == '--' ? 'hours-design hours-design-danger' : 'hours-design'}>{workDuration.duration}</h5>
                                </div>
                            </div>
                        </div>

                    </div>

            </div>

        </div>
    );
};

export default Calculate;
