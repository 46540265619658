import React, {useState} from 'react';
import {faArrowDown, faEye, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import '@fortawesome/free-solid-svg-icons'
import CommonTable from "./CommonTable";


const RecentOrder = (props) => {

    const [tableColumn, setTableColumn] = useState([
        {name: 'ORDER NO'},
        {name: 'DATE'},
        {name: 'CUSTOMER NAME'},
        {name: 'ADDRESS'},
        {name: 'PAYMENT METHOD'},
        {name: 'TOTAL AMOUNT'},
        {name: 'STATUS'},
        {name: 'ACTION'},
    ])
    const [tableData, setTableData] = useState([
        [
            {name: '252545524'},
            {name: '2 Sep, 20202 Sep, 2020'},
            {name: 'Customer Full Name'},
            {name: 'Shipping Address'},
            {name: 'Bkash'},
            {name: '৳ 2400'},
            {name: '<button class="btn-pending">In Transit</button>'},
            {name: '<Link className="action-eye" to={"/"}><i className="fas fa-eye"/> </Link>'},
        ],
        [
            {name: '252545524'},
            {name: '2 Sep, 20202 Sep, 2020'},
            {name: 'Customer Full Name'},
            {name: 'Shipping Address'},
            {name: 'Bkash'},
            {name: '৳ 2400'},
            {name: '<button class="btn-pending">In Transit</button>'},
            {name: '<Link className="action-eye" to={"/"}></Link>'},
        ]
    ])

    return (
        <div className="row mt-4">
            <div className="col-md-12">
                <div className="top-selling-table-section">
                    <div className="top-selling-header">
                        <h5 className={'top-selling-product-name'}>Recent Order</h5>
                        <div className="see-all">
                            <Link className={'see-all-export'} to={''}>See All <i className="fas fa-eye"/></Link>
                            <Link className={'see-all-export custom-export'} to={''}><span
                                className={'title-export'}>Export</span><FontAwesomeIcon icon={faArrowDown}
                                                                                         className={'export-arrow-down'}/></Link>
                        </div>
                    </div>
                    <CommonTable tableColumn={tableColumn} tableData={tableData}/>
                </div>
            </div>
        </div>
    );
};

export default RecentOrder;
