import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleUp} from "@fortawesome/free-solid-svg-icons";

const HeaderContent = () => {
    return (

            <div className="row">
                <div className="col-md-3">
                    <div className="order-section">
                        <div className="row">
                            <div className="col-8">
                                <div className="order-inner">
                                    <div className="order-details">
                                        <p className={'order'}>Order</p>
                                        <p className={'order new-order'}>54200</p>
                                    </div>
                                    <div className="month-and-icon">
                                        <FontAwesomeIcon icon={faArrowAltCircleUp} className={''}/>
                                        <p className={'this-month'}>This Month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="icon-and-parent">
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                                    <p>+ 12%</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col-md-3">
                    <div className="order-section tow-order-color">
                        <div className="row">
                            <div className="col-8">
                                <div className="order-inner">
                                    <div className="order-details">
                                        <p className={'order'}>Order</p>
                                        <p className={'order new-order'}>54200</p>
                                    </div>
                                    <div className="month-and-icon">
                                        <FontAwesomeIcon icon={faArrowAltCircleUp} className={''}/>
                                        <p className={'this-month'}>This Month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="icon-and-parent">
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                                    <p>+ 12%</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col-md-3">
                    <div className="order-section three-order-color">
                        <div className="row">
                            <div className="col-8">
                                <div className="order-inner">
                                    <div className="order-details">
                                        <p className={'order'}>Order</p>
                                        <p className={'order new-order'}>54200</p>
                                    </div>
                                    <div className="month-and-icon">
                                        <FontAwesomeIcon icon={faArrowAltCircleUp} className={''}/>
                                        <p className={'this-month'}>This Month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="icon-and-parent">
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                                    <p>+ 12%</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col-md-3">
                    <div className="order-section four-order-color">
                        <div className="row">
                            <div className="col-8">
                                <div className="order-inner">
                                    <div className="order-details">
                                        <p className={'order'}>Order</p>
                                        <p className={'order new-order'}>54200</p>
                                    </div>
                                    <div className="month-and-icon">
                                        <FontAwesomeIcon icon={faArrowAltCircleUp} className={''}/>
                                        <p className={'this-month'}>This Month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="icon-and-parent">
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                                    <p>+ 12%</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


    );
};

export default HeaderContent;
