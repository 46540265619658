import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faEdit, faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import loader from "../../../Assets/Images/loader/ripple.svg";
import Pagination from "react-js-pagination";
import {Modal} from "react-bootstrap";

const ViewPunch = () => {


    const [searchString, setSearchString] = useState('')
    const [order_by, setOrderBy] = useState('order_by')
    const [sortDirection, setSortDirection] = useState('asc')
    const [perPageItem, setPerPageItem] = useState(20)

    const handleOrderBy = (e) => {
        setOrderBy(e.target.value)
    }
    const handleSortDirection = (e) => {
        setSortDirection(e.target.value)
    }
    const setPerPageData = (e) => {
        setPerPageItem(e.target.value)
    }
    const handleModal = () => {

    }

    return (
        <div className="row margin-top-in-custom">
            <div className="col-md-12">
                <div className="top-selling-table-section">
                    <div className="all-order-title">
                        <h5 className={'top-selling-product-name'}>View Punch</h5>
                    </div>

                    <div className="row mt-5 mb-4">
                        <div className="col-md-3">
                            <div className="search-field-and-icon position-relative">
                                <input onChange={(e) => setSearchString(e.target.value)} type={'search'}
                                       className={'manage-order-search'}
                                       placeholder={'Search'}/>
                                {/*<FontAwesomeIcon icon={faSearch} className={'search-icon-in-all'}/>*/}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className={'d-flex justify-content-end'}>Sort By
                                        <select defaultValue={order_by} onChange={handleOrderBy}
                                                className="form-select custom-form-select-in-order ms-2">
                                            <option value={'brand'}>Brand Name</option>
                                            <option value={'slug'}>Slug</option>
                                            <option value={'order_by'}>Order By</option>
                                            <option value={'status'}>Status</option>
                                            <option value={'created_at'}>Creation Time</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className={'d-flex'}>ASC/DESC
                                                <select onChange={handleSortDirection}
                                                        className="form-select custom-form-select-in-order ms-2">
                                                    <option value={'asc'}>ASC</option>
                                                    <option value={'desc'}>DESC</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <label className={'d-flex'}>Show
                                                <select onChange={setPerPageData}
                                                        className="form-select custom-form-select-in-order ms-2">
                                                    <option value={20}>20 Entries</option>
                                                    <option value={50}>50 Entries</option>
                                                    <option value={100}>100 Entries</option>
                                                    <option value={200}>200 Entries</option>
                                                    <option value={500}>500 Entries</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end">
                                    <button type={'button'} className={'see-all-export custom-export'}
                                            onClick={handleModal}>
                                        <span className={'title-export'}>+Add New Brand</span>
                                        <FontAwesomeIcon icon={faArrowDown} className={'export-arrow-down'}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <table className={'delivery-information-table custom-box-shadow-inner'}>
                            <thead>
                            <tr>
                                <th>SL</th>
                                <th>User ID</th>
                                <th>User Name</th>
                                <th>Punch Date</th>
                                <th>Working Hours</th>
                                <th>First/Last Punch</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            {/*<tbody>*/}
                            {/*{brands.map((brand, index) => (*/}
                            {/*    <tr key={index}>*/}
                            {/*        <td>{serial + index} </td>*/}
                            {/*        <td>{brand.brand}</td>*/}
                            {/*        <td>*/}
                            {/*            <img src= {brand.logo} alt={brand.brand} className={'img-thumbnail list-table-image'}/>*/}
                            {/*        </td>*/}
                            {/*        <td>{brand.slug}</td>*/}
                            {/*        <td>{brand.order_by}</td>*/}
                            {/*        <td>*/}
                            {/*            <div className="form-switch">*/}
                            {/*                <input onChange={(e) => handleStatusChange(e, brand.id)}*/}
                            {/*                       className="form-check-input custom-switch" value={brand.status}*/}
                            {/*                       type="checkbox" checked={brand.status}/>*/}
                            {/*            </div>*/}
                            {/*        </td>*/}
                            {/*        <td>{brand.user}</td>*/}
                            {/*        <td>*/}
                            {/*            <button onClick={(e) => handleBrandShow(e, brand.id)}*/}
                            {/*                    className={'btn btn-info btn-sm'}><FontAwesomeIcon icon={faEye}/>*/}
                            {/*            </button>*/}
                            {/*            <button onClick={(e) => handleEditModal(e, brand.id)}*/}
                            {/*                    className={'btn btn-warning btn-sm ms-1'}><FontAwesomeIcon*/}
                            {/*                icon={faEdit}/></button>*/}
                            {/*            <button onClick={(e) => handleDelete(e, brand.id)}*/}
                            {/*                    className={'btn btn-danger btn-sm ms-1'}><FontAwesomeIcon*/}
                            {/*                icon={faTrash}/></button>*/}
                            {/*        </td>*/}
                            {/*    </tr>*/}
                            {/*))}*/}
                            {/*</tbody>*/}
                        </table>
                        {/*{Object.keys(brands).length < 1 && isLoading == false ?*/}
                        {/*    <div className={'data-not-found'}>No Data Found</div> : null}*/}
                        {/*{isLoading ?*/}
                        {/*    <div className="loader">*/}
                        {/*        <img className={'loader-image'} src={loader} alt={'Loader'}/>*/}
                        {/*    </div> : null*/}
                        {/*}*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-6">*/}
                        {/*        Showing {serial} to {serial + perPageItem - 1 <= totalItemsCount ? serial + perPageItem - 1 : totalItemsCount} of {totalItemsCount} entries*/}
                        {/*    </div>*/}
                        {/*    <div className="col-md-6">*/}
                        {/*        {totalItemsCount > perPageItem ?*/}
                        {/*            <nav className={'mt-2'}>*/}
                        {/*                <Pagination*/}
                        {/*                    innerClass={'pagination justify-content-end'}*/}
                        {/*                    activePage={activePage}*/}
                        {/*                    itemsCountPerPage={itemsCountPerPage}*/}
                        {/*                    totalItemsCount={totalItemsCount}*/}
                        {/*                    // pageRangeDisplayed={5}*/}
                        {/*                    onChange={getBrands}*/}
                        {/*                    nextPageText="Next"*/}
                        {/*                    prevPageText="Previous"*/}
                        {/*                    firstPageText="First"*/}
                        {/*                    lastPageText="Last"*/}
                        {/*                    itemClass="page-item"*/}
                        {/*                    linkClass="page-link"*/}
                        {/*                />*/}
                        {/*            </nav> : null*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </div>


                </div>
            </div>

        </div>
    );
};

export default ViewPunch;
