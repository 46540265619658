import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faBars, faChevronRight, faList, faSearch} from "@fortawesome/free-solid-svg-icons";
import logo from '../../Assets/Images/brand_logo.png'
import {Link} from "react-router-dom";
import $ from 'jquery';

const SideNavBar = () => {

    const subMenuShowHandler = (id) => {
        let has_active_class = $('.menu-active')
        for (let j = 0; j <= has_active_class.length; j++) {
            $('.menu-active', [j]).removeClass('menu-active')
        }
        $('#' + id).children('.dashboard-item').children('.menu-icon-end').css('transform', 'rotate(90deg)')
        // $('#' + id).children('.nav-main-submenu').slideToggle('fast')
        let display_status = $('#' + id).css('display')
        if (display_status != 'none') {
            $('#' + id).addClass('menu-active')
            $('#' + id).children('.nav-main-submenu').slideDown('fast')
        } else {
            $('#' + id).children('.nav-main-submenu').slideUp('fast')
        }

        let element_id = id.split('_')
        let length = Object.keys(menus).length
        for (let i = 0; i < length; i++) {
            if (i != element_id[1]) {
                $('#mainMenu_' + i).children('.nav-main-submenu').slideUp('fast')

                let rotate = $('#mainMenu_' + i).children('.dashboard-item').children('.menu-icon-end').css('transform')
                if (rotate == 'matrix(6.12323e-17, 1, -1, 6.12323e-17, 0, 0)') {
                    $('#mainMenu_' + i).children('.dashboard-item').children('.menu-icon-end').css('transform', 'rotate(0deg)')
                }
            }
        }
    }

    const handleSubMenuClick = (id) => {
        console.log('hello')
       let elements  = $('.submenu-title')
        console.log(elements)
        for (let i = 0; i<elements.length ; i++){
            $('.submenu-title',[i]).removeClass('submenu-active')
        }
        $('#'+id).addClass('submenu-active')
    }
    const [menus] = useState([
        {
            name: 'Dashboard',
            path: '/',
            icon: 'faSearch',
        },
        {
            name: 'Punch Calculate',
            path: '/punch/calculate',
            icon: 'faSearch',
            permission : 1
        },

    ])

    // const handleMainMenuClick = () =>{
    //     return menu.subMenu !=undefined && Object.keys(menu.subMenu).length > 0 ?  false:  true
    // }
    const [searchTerm, setSearchTerm] = useState("")

    // var show = document.getElementsByClassName('navbar-section-main');
    // localStorage.showMenu=show;
    return (
        <>

            <div className="navbar-section-main">
                <div className="navbar-top-icon">
                    <img src={logo} className={'img-fluid rounded-logo'} alt="logo"/>
                </div>
                <div className="navbar-search-section">
                    <form>
                        <input type={'text'} className={'input-search-box'} placeholder="Search" onChange={event => {
                            setSearchTerm(event.target.value)
                        }}/>
                        <FontAwesomeIcon icon={faSearch} className={'top-search-icon-nav'}/>
                    </form>
                </div>

                <div className="nav-menu-section">
                    {menus.filter((menu => {
                        if (searchTerm == "") {
                            return menu
                        } else if (menu.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return menu
                        }
                    })).map((menu, index) => (
                        <div className="nav-main-menu" id={`mainMenu_${index}`}
                             onClick={() => subMenuShowHandler(`mainMenu_${index}`)} key={index}>
                            <Link to={menu.path}
                                  onClick={menu.subMenu != undefined && Object.keys(menu.subMenu).length > 0 ? (event) => event.preventDefault() : null}
                                  className={'dashboard-item'}>
                                <div className="nav-menu-item-inner">
                                    <FontAwesomeIcon icon={faBars} className={'me-2'}/>
                                    {menu.name}
                                </div>
                                {menu.subMenu != undefined ?
                                    <FontAwesomeIcon icon={faChevronRight} className={'menu-icon-end'}/> : null}
                            </Link>
                            {menu.subMenu != undefined ?
                                <div className="nav-main-submenu" style={{display: 'none'}}>

                                    {menu.subMenu.map((submenu, subIndex) => (
                                        <>
                                            {submenu.permission == undefined || submenu.permission == localStorage.role_id?
                                                <Link to={submenu.path} className={'submenu-title'}
                                                      onClick={() => handleSubMenuClick('submenu_title_' + subIndex+index)}
                                                      id={'submenu_title_' + subIndex+index} key={subIndex}>
                                                    <div className="submenu">
                                                        <FontAwesomeIcon icon={faArrowRight} className={'me-2'}/>
                                                        {submenu.name}
                                                    </div>
                                                </Link>:null
                                            }

                                        </>

                                    ))}

                                </div> : null
                            }
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default SideNavBar;
