import React, {useEffect, useState} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import axios from "axios";
import BASEURL from "./Constants";

const Auth = ({ component : Component, ...rest}) => {
    const [auth, setAuth] = useState(false);
    const [url , setUrl]= useState( null)

    useEffect(()=>{
        handleAuthentication()
    },[])
    const history = useHistory();

    const handleAuthentication = () => {
        setUrl(window.location.pathname)
        const token = localStorage.user_token
        if (token){
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        if (url != '/login'){
            axios.post(BASEURL+'/api/check-user-auth').then(res=>{
                if (res.data.role_id == localStorage.role_id && res.data.user_id == localStorage.user_id){
                    setAuth(res.data.msg)
                }else {
                    localStorage.removeItem('user_token')
                    localStorage.removeItem('name')
                    localStorage.removeItem('email')
                    localStorage.removeItem('photo')
                    localStorage.removeItem('user_id')
                    localStorage.removeItem('role_id')
                    localStorage.removeItem('photo_thumb')
                    history.push('/login')
                    window.location.reload()
                }
            })
        }
    }

    const token = localStorage.user_token
    return(
        <Route
            {...rest}
            render={props=>
                token != undefined ? (
                    <Component {...props} />
                ):(
                    <Redirect
                        to={{
                            pathname:"/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    )
}
export default Auth
