import React from 'react';
import img1 from '../../Assets/Images/4.jpg'

const TopSelling = () => {
    return (
        <div className="row mt-4">
            <div className="col-md-6">
                <div className="top-selling-table-section new-top-selling">
                    <div className="top-selling-header">
                        <h5 className={'top-selling-product-name'}>Top Selling Products</h5>
                        <div className="extra-line"></div>
                    </div>
                    <div className="top-selling-content-table">
                        <div className="top-selling-product-name-and-order">
                            <p className={'top-product-title'}>PRODUCT NAME</p>
                            <p className={'top-product-title'}>ORDERS</p>
                        </div>

                        <div className="top-selling-order-box">
                            <div className="top-selling-image">
                                <img src={img1} className={'img-fluid custom-img-size'} alt=""/>
                                <div className="top-selling-name-and-price">
                                    <p>Fish Scale Plastic Tools</p>
                                    <p>৳ 70</p>
                                </div>
                            </div>

                            <div className="top-selling-order">
                                <p>2542</p>
                            </div>
                        </div>

                        <div className="top-selling-order-box">
                            <div className="top-selling-image">
                                <img src={img1} className={'img-fluid custom-img-size'} alt=""/>
                                <div className="top-selling-name-and-price">
                                    <p>Fish Scale Plastic Tools</p>
                                    <p>৳ 70</p>
                                </div>
                            </div>

                            <div className="top-selling-order">
                                <p>2542</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="top-selling-table-section new-top-selling">
                    <div className="top-selling-header">
                        <h5 className={'top-selling-product-name'}>New Users</h5>
                    </div>
                    <div className="top-selling-content-table">
                        <div className="top-selling-product-name-and-order">
                            <p className={'top-product-title'}>NAME</p>
                            <p className={'top-product-title'}>JOINING TIME</p>
                        </div>

                        <div className="top-selling-order-box">
                            <div className="top-selling-image">
                                <img src={img1} className={'img-fluid custom-img-size'} alt=""/>
                                <div className="top-selling-name-and-price">
                                    <p>Fish Scale Plastic Tools</p>
                                    <p>৳ 70</p>
                                </div>
                            </div>

                            <div className="top-selling-order">
                                <p>1 hrs ago</p>
                            </div>
                        </div>

                        <div className="top-selling-order-box">
                            <div className="top-selling-image">
                                <img src={img1} className={'img-fluid custom-img-size'} alt=""/>
                                <div className="top-selling-name-and-price">
                                    <p>Fish Scale Plastic Tools</p>
                                    <p>৳ 70</p>
                                </div>
                            </div>

                            <div className="top-selling-order">
                                <p>1 hrs ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopSelling;
