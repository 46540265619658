import React from 'react';

const CommonTable = (props) => {
    return (
        <div>
            <div className="others-section bg-white custom-shadow-without-top">
                <div className="delivery-information-table-section">
                    <table className={'delivery-information-table custom-box-shadow-inner'}>
                        <thead>
                        <tr>
                            {props.tableColumn.map((column, index)=>(
                                <th key={index}>{column.name}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {props.tableData.map((data, index)=>(
                            <tr key={index}>
                                {data.map((tableData, dataIndex)=>(
                                    <td dangerouslySetInnerHTML={{__html:tableData.name}} key={dataIndex}></td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CommonTable;
