import React from 'react';
import {Bar, Doughnut} from "react-chartjs-2";


const ChartDashboard = () => {

    const data = {
        labels: ['jan', 'feb', 'mar', 'april', 'may', 'june', 'july'],
        datasets: [
            {
                label: 'lebel1',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: '#C39BD3',
            },
            {
                label: 'lebel2',
                data: [20, 30, 40, 50, 60, 70, 80],
                backgroundColor: '#5DADE2',
            }
        ]
    };
    const data2 = {
        labels: [
            'Red',
            'Blue',
            'Yellow'
        ],
        datasets: [{
            label: 'Pie Chart',
            data: [300, 50, 100],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
        }]
    };
    return (
        <div className="row mt-4">
            <div className="col-md-8">
                <div className="bar-chart-section">
                    <div className="bar-chart-title">
                        <h5 className={'chart-name'}>Business Overview</h5>
                        <div className="bar-chart-button">
                            <button className={'chart-btn'}>Weekly</button>
                            <button className={'chart-btn'}>Monthly</button>
                            <button className={'chart-btn'}>Yearly</button>
                        </div>
                    </div>
                    <Bar data={data}/>
                </div>
            </div>
            <div className="col-md-4">
                <div className="bar-chart-section h-100">
                    <div className="pie-bar-chart-title">
                        <h5 className={'chart-name text-center'}>Order Summary</h5>
                    </div>
                    <Doughnut data={data2}/>
                </div>

            </div>
        </div>
    );
};

export default ChartDashboard;
