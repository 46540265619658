import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import BASEURL from "../../../Constants";
import {toast, ToastContainer} from "react-toastify";

const CreatePunch = () => {

    const [user_id, setUser_id] = useState('')
    const [users, setUsers] = useState([])
    const [input, setInput] = useState({})
    const [error, setError] = useState({})

    const getUser = () => {
        axios.get(BASEURL + '/api/user-list').then(res => {
            setUsers(res.data)
        })
    }

    const handleUserSelection = (e) => {
        setUser_id(e.target.value)
    }

    const handleInput = (e) => {
        setInput(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    useEffect(() => {
        getUser()
    }, [])

    const addPunch = (e) => {
       // e.preventDefault()
        console.log(input)
        axios.post(BASEURL+'/api/add-punch', input).then(res=>{
                toast.success(res.data.msg)
                setInput({user_id:'', punch_time : ''})
            setError([])
        }).catch(errors => {
            if (errors.response.status === 422) {
                setError([])
                setError(errors.response.data.errors)
                toast.warn('Something Going Wrong! Please Fix');
            }else if(errors.response.status === 401 ){
                toast.warn(errors.response.data.error);
            }
        })
    }


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Helmet>
                <title>Punches Create | iIT Team</title>
            </Helmet>
            <div className="row margin-top-in-custom">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="all-order-title position-relative">
                            <h5 className={'top-selling-product-name'}>Add Punch</h5>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-6">

                                <label
                                    className={'label-item'}
                                >
                                    <select
                                        onChange={handleInput}
                                        className="form-select user-form-control new-control"
                                        name={'user_id'}
                                        value={input.user_id}
                                    >
                                        {users.map((user, index) => (
                                            <option
                                                key={index}
                                                value={user.user_id}>{user.user_id} - {user.name}</option>
                                        ))}
                                    </select>
                                    {Object.entries(error).map(([key, val]) => (
                                        <small
                                            className="text-danger validation-error-message"
                                            key={key}>{key == 'user_id' ? val : ''}</small>
                                    ))}
                                </label>

                                <label className={'label-item mt-3'}>
                                    <input
                                        name={'punch_time'}
                                        onChange={handleInput}
                                        type="datetime-local"
                                        className="form-control user-form-control new-control"
                                        placeholder={'Brand Name'}
                                        value={input.punch_time}
                                    />
                                    {Object.entries(error).map(([key, val]) => (
                                        <small
                                            className="text-danger validation-error-message"
                                            key={key}>{key == 'punch_time' ? val : ''}</small>
                                    ))}
                                </label>

                                <div className="d-grid">
                                    <button type={'submit'} onClick={addPunch} className={'btn btn-success btn-sm mt-3'}>Add Punch
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CreatePunch;
