import React from 'react';
import HeaderContent from "../Include/HeaderContent";
import TopSelling from "../Include/TopSelling";
import RecentOrder from "../Include/RecentOrder";
import ChartDashboard from "../Include/ChartDashboard";
import {Helmet} from "react-helmet";

const Home = () => {

    return (
      <>
          <Helmet>
              <title>Dashboard | iIT Team</title>
          </Helmet>
          <HeaderContent/>
          <ChartDashboard/>
          <RecentOrder/>
          <TopSelling/>
      </>
    );
};

export default Home;
